<template>
  <base-material-card :title="$t('configuration')" fluid id="alerts">
    <v-row justify="center" class="pt-8">
      <v-col cols="1" v-if="!$store.getters['auth/isStudio']"></v-col>
      <v-col
        cols="6"
        sm="4"
        lg="3"
        md="3"
        xl="3"
        class="py-0 px-2 px-md-5 my-md-3 my-0"
      >
        <Appointments />
      </v-col>

      <v-col
        cols="6"
        sm="4"
        lg="3"
        md="3"
        xl="3"
        class="py-0 px-2 px-md-5 my-md-3 my-0"
      >
        <Services />
      </v-col>
      <v-col
        cols="6"
        sm="4"
        lg="3"
        md="3"
        xl="3"
        class="py-0 px-2 px-md-5 my-md-3 my-0"
      >
        <Calendar />
      </v-col>

      <v-col
        cols="6"
        sm="4"
        lg="3"
        md="3"
        xl="3"
        class="py-0 px-2 px-md-5 my-md-3 my-0"
      >
        <ConsentForm />
      </v-col>
      <v-col cols="1" v-if="!$store.getters['auth/isStudio']"></v-col>
      <v-col
        cols="6"
        sm="4"
        lg="3"
        md="3"
        xl="3"
        class="py-0 px-2 px-md-5 my-md-3 my-0"
      >
        <ImportExport />
      </v-col>
      <v-col
        v-if="$store.getters['auth/isStudio']"
        cols="6"
        sm="4"
        lg="3"
        md="3"
        xl="3"
        class="py-0 px-2 px-md-5 my-md-3 my-0"
      >
        <Boxes @changeTab="$emit('changeTab')" />
      </v-col>
      <v-col
        cols="6"
        sm="4"
        lg="3"
        md="3"
        xl="3"
        class="py-0 px-2 px-md-5 my-md-3 my-0"
      >
        <Alerts />
      </v-col>
      <v-col
        cols="6"
        sm="4"
        lg="3"
        md="3"
        xl="3"
        class="py-0 px-2 px-md-5 my-md-3 my-0"
      >
        <QR />
      </v-col>
    </v-row>
  </base-material-card>
</template>
<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "Configuracion",
  mounted() {},
  directives: {
    mask,
  },
  watch: {
    menu3(val) {
      val &&
        setTimeout(() => {
          this.$refs.picker.activePicker = "YEAR";
        });
    },
  },
  components: {
    users: () => import("@/views/users/UserList"),
    CardConf: () => import("@/components/user/settings/Card"),
    Alerts: () => import("@/components/user/settings/configuracion/Alerts"),
    Services: () => import("@/components/user/settings/configuracion/Services"),
    Appointments: () =>
      import("@/components/user/settings/configuracion/Appointments"),
    Boxes: () => import("@/components/user/settings/configuracion/Boxes"),
    Calendar: () => import("@/components/user/settings/configuracion/Calendar"),
    Comisions: () =>
      import("@/components/user/settings/configuracion/Comisions"),
    ConsentForm: () =>
      import("@/components/user/settings/configuracion/ConsentForm"),
    ImportExport: () =>
      import("@/components/user/settings/configuracion/ImportExport"),
    QR: () => import("@/components/user/settings/configuracion/QR"),
  },
  data() {
    return {
      activated: false,

      tab: "qr",
      menu3: false,
      items: [this.$t("yes"), this.$t("no")],

      key: {
        alerts: false,
        boxes: false,
        appointments: false,
        qr: false,
        calendar: false,
        consentForm: false,
      },
    };
  },
  methods: {
    ...mapActions("users", ["settingUpdate", "getSetting"]),

    ...mapActions("users", ["settingUpdate", "getSetting"]),
    fetchSetting() {
      this.loading = true;
      this.getSetting({ key: this.keys }).then((boxes) => {
        //console.log(boxes.data);

        this.active = JSON.parse(boxes.data.value);
        //console.log(this.active);
        this.loading = false;
      });
    },
    settingCreateOrUpdate(item) {
      //console.log(this.keys);
      //console.log(item);
      this.settingUpdate({
        key: this.keys,
        value: item,
      }).then((data) => {
        this.fetchSetting();
      });
    },

    async validateForm(scope) {
      //console.log("validando");
      this.errors.clear();
      this.$validator.validateAll(scope).then((result) => {});
    },

    btnActivated(value) {
      this.activated = value;
    },
  },

  computed: {},
};
</script>

<style lang="sass">
.payment
  .info
    background-color: transparent !important
    position: absolute
    right: 8px
    top: 80px
    cursor: pointer
#consent-form
  .v-data-table
    table
      min-width: 650px

.popupCard2

  color: red !important
  width: 100% !important
  background-color: var(--v-gris3-base) !important
  .close .v-btn
    top: 0
    right: 0px
    position: absolute

    .v-icon
      color: var(--v-primary-base) !important
      position: absolute

#alerts
  label
    display: block
    white-space: nowrap
    height: 25px
</style>
